@font-face {
    font-family: 'axa-icons';
    src: url('/fonts/axa-icons.woff2') format('woff2'), url('/fonts/axa-icons.woff') format('woff'),
        url('/fonts/axa-icons.ttf') format('truetype'), url('/fonts/axa-icons.svg#maw_icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class*='axa-icons-']:before {
    display: inline-flex;
    line-height: 1;
    font-family: axa-icons !important;
    speak: none;
    font-weight: 400;
    font-style: normal;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.axa-icons-24-7-o:before {
    content: '\F101';
}

.axa-icons-add-circle:before {
    content: '\F102';
}

.axa-icons-ads-o:before {
    content: '\F103';
}

.axa-icons-agent-card:before {
    content: '\F104';
}

.axa-icons-agent:before {
    content: '\F105';
}

.axa-icons-air-bah-vest-o:before {
    content: '\F106';
}

.axa-icons-aircraft-o:before {
    content: '\F107';
}

.axa-icons-airline-seat:before {
    content: '\F108';
}

.axa-icons-ambulance-o:before {
    content: '\F109';
}

.axa-icons-amputation-o:before {
    content: '\F10A';
}

.axa-icons-apartment-block-o:before {
    content: '\F10B';
}

.axa-icons-apartment:before {
    content: '\F10C';
}

.axa-icons-apps:before {
    content: '\F10D';
}

.axa-icons-arm-plaster-man-o:before {
    content: '\F10E';
}

.axa-icons-arm-plaster-o:before {
    content: '\F10F';
}

.axa-icons-arrow-back:before {
    content: '\F110';
}

.axa-icons-arrow-down:before {
    content: '\F111';
}

.axa-icons-arrow-downward:before {
    content: '\F112';
}

.axa-icons-arrow-drop-down:before {
    content: '\F113';
}

.axa-icons-arrow-drop-up:before {
    content: '\F114';
}

.axa-icons-arrow-forward:before {
    content: '\F115';
}

.axa-icons-arrow-left:before {
    content: '\F116';
}

.axa-icons-arrow-o:before {
    content: '\F117';
}

.axa-icons-arrow-right:before {
    content: '\F118';
}

.axa-icons-arrow-up:before {
    content: '\F119';
}

.axa-icons-arrow-upward:before {
    content: '\F11A';
}

.axa-icons-attachment:before {
    content: '\F11B';
}

.axa-icons-award-o:before {
    content: '\F11C';
}

.axa-icons-baby-o:before {
    content: '\F11D';
}

.axa-icons-back-pain-o:before {
    content: '\F11E';
}

.axa-icons-bank-dollar-o:before {
    content: '\F11F';
}

.axa-icons-bank-euro-o:before {
    content: '\F120';
}

.axa-icons-bank-o:before {
    content: '\F121';
}

.axa-icons-bank-pound-o:before {
    content: '\F122';
}

.axa-icons-bank-yen-o:before {
    content: '\F123';
}

.axa-icons-bicycle:before {
    content: '\F124';
}

.axa-icons-bills-dollar-o:before {
    content: '\F125';
}

.axa-icons-bills-euro-o:before {
    content: '\F126';
}

.axa-icons-bills-pound-o:before {
    content: '\F127';
}

.axa-icons-bills-yen-o:before {
    content: '\F128';
}

.axa-icons-boat:before {
    content: '\F129';
}

.axa-icons-breakdown-24-o:before {
    content: '\F12A';
}

.axa-icons-breakdown-truck-1-o:before {
    content: '\F12B';
}

.axa-icons-breakdown-truck-24-o:before {
    content: '\F12C';
}

.axa-icons-breakdown-truck-o:before {
    content: '\F12D';
}

.axa-icons-breakdown-truck-one-hour-o:before {
    content: '\F12E';
}

.axa-icons-breakpoints-o:before {
    content: '\F12F';
}

.axa-icons-briefcase-o:before {
    content: '\F130';
}

.axa-icons-broken-bottle-o:before {
    content: '\F131';
}

.axa-icons-broken-glass-o:before {
    content: '\F132';
}

.axa-icons-broken-glass:before {
    content: '\F133';
}

.axa-icons-broken-robot-o:before {
    content: '\F134';
}

.axa-icons-building-o:before {
    content: '\F135';
}

.axa-icons-building:before {
    content: '\F136';
}

.axa-icons-buoy-o:before {
    content: '\F137';
}

.axa-icons-burger-menu:before {
    content: '\F138';
}

.axa-icons-bus:before {
    content: '\F139';
}

.axa-icons-cabriolet-o:before {
    content: '\F13A';
}

.axa-icons-calculator-o:before {
    content: '\F13B';
}

.axa-icons-calendar-car:before {
    content: '\F13C';
}

.axa-icons-calendar-o:before {
    content: '\F13D';
}

.axa-icons-calendar:before {
    content: '\F13E';
}

.axa-icons-camping-car:before {
    content: '\F13F';
}

.axa-icons-car-accident-o:before {
    content: '\F140';
}

.axa-icons-car-o:before {
    content: '\F141';
}

.axa-icons-car-sharing-o:before {
    content: '\F142';
}

.axa-icons-car-substitution-o:before {
    content: '\F143';
}

.axa-icons-car:before {
    content: '\F144';
}

.axa-icons-cars-o:before {
    content: '\F145';
}

.axa-icons-cast:before {
    content: '\F146';
}

.axa-icons-castle-o:before {
    content: '\F147';
}

.axa-icons-chat-doctor-o:before {
    content: '\F148';
}

.axa-icons-chat-man-o:before {
    content: '\F149';
}

.axa-icons-chat-o:before {
    content: '\F14A';
}

.axa-icons-chat-woman-o:before {
    content: '\F14B';
}

.axa-icons-chat:before {
    content: '\F14C';
}

.axa-icons-check-1:before {
    content: '\F14D';
}

.axa-icons-check-2:before {
    content: '\F14E';
}

.axa-icons-check-box:before {
    content: '\F14F';
}

.axa-icons-check-circle:before {
    content: '\F150';
}

.axa-icons-check-full:before {
    content: '\F151';
}

.axa-icons-check-o:before {
    content: '\F152';
}

.axa-icons-cheque-o:before {
    content: '\F153';
}

.axa-icons-child-o:before {
    content: '\F154';
}

.axa-icons-chimney:before {
    content: '\F155';
}

.axa-icons-cigarette-o:before {
    content: '\F156';
}

.axa-icons-citycar-o:before {
    content: '\F157';
}

.axa-icons-clean-car-o:before {
    content: '\F158';
}

.axa-icons-clear:before {
    content: '\F159';
}

.axa-icons-clock-o:before {
    content: '\F15A';
}

.axa-icons-clock:before {
    content: '\F15B';
}

.axa-icons-cloud:before {
    content: '\F15C';
}

.axa-icons-coins-dollar-o:before {
    content: '\F15D';
}

.axa-icons-coins-euro-o:before {
    content: '\F15E';
}

.axa-icons-coins-less-more-o:before {
    content: '\F15F';
}

.axa-icons-coins-o:before {
    content: '\F160';
}

.axa-icons-coins-pound-o:before {
    content: '\F161';
}

.axa-icons-coins-yen-o:before {
    content: '\F162';
}

.axa-icons-company-o:before {
    content: '\F163';
}

.axa-icons-cone-o:before {
    content: '\F164';
}

.axa-icons-contamination-o:before {
    content: '\F165';
}

.axa-icons-contract-check-o:before {
    content: '\F166';
}

.axa-icons-contract-o:before {
    content: '\F167';
}

.axa-icons-contract-termination-o:before {
    content: '\F168';
}

.axa-icons-conversation-o:before {
    content: '\F169';
}

.axa-icons-credit-card-o:before {
    content: '\F16A';
}

.axa-icons-critical-illness-o:before {
    content: '\F16B';
}

.axa-icons-cross:before {
    content: '\F16C';
}

.axa-icons-cursor-o:before {
    content: '\F16D';
}

.axa-icons-custody-o:before {
    content: '\F16E';
}

.axa-icons-cutlery-o:before {
    content: '\F16F';
}

.axa-icons-damage:before {
    content: '\F170';
}

.axa-icons-danger-o:before {
    content: '\F171';
}

.axa-icons-danger:before {
    content: '\F172';
}

.axa-icons-death-o:before {
    content: '\F173';
}

.axa-icons-dental-o:before {
    content: '\F174';
}

.axa-icons-desktop-o:before {
    content: '\F175';
}

.axa-icons-desktop:before {
    content: '\F176';
}

.axa-icons-devices:before {
    content: '\F177';
}

.axa-icons-discount-10-o:before {
    content: '\F178';
}

.axa-icons-discount-20-o:before {
    content: '\F179';
}

.axa-icons-discount-25-o:before {
    content: '\F17A';
}

.axa-icons-discount-40-o:before {
    content: '\F17B';
}

.axa-icons-discount-50-o:before {
    content: '\F17C';
}

.axa-icons-document-o:before {
    content: '\F17D';
}

.axa-icons-document:before {
    content: '\F17E';
}

.axa-icons-dollar:before {
    content: '\F17F';
}

.axa-icons-download-o:before {
    content: '\F180';
}

.axa-icons-download:before {
    content: '\F181';
}

.axa-icons-dressing:before {
    content: '\F182';
}

.axa-icons-drope:before {
    content: '\F183';
}

.axa-icons-earth-o:before {
    content: '\F184';
}

.axa-icons-earth-shield-o:before {
    content: '\F185';
}

.axa-icons-electric-car-o:before {
    content: '\F186';
}

.axa-icons-email-1:before {
    content: '\F187';
}

.axa-icons-email-2:before {
    content: '\F188';
}

.axa-icons-email-o:before {
    content: '\F189';
}

.axa-icons-employee-o:before {
    content: '\F18A';
}

.axa-icons-euro-car-o:before {
    content: '\F18B';
}

.axa-icons-euro:before {
    content: '\F18C';
}

.axa-icons-extractor-hood-o:before {
    content: '\F18D';
}

.axa-icons-eye-o:before {
    content: '\F18E';
}

.axa-icons-facebook-o:before {
    content: '\F18F';
}

.axa-icons-facebook:before {
    content: '\F190';
}

.axa-icons-factory-o:before {
    content: '\F191';
}

.axa-icons-fall-stairs-o:before {
    content: '\F192';
}

.axa-icons-family-1-o:before {
    content: '\F193';
}

.axa-icons-family-2-o:before {
    content: '\F194';
}

.axa-icons-family-3-o:before {
    content: '\F195';
}

.axa-icons-family-4-o:before {
    content: '\F196';
}

.axa-icons-family-5-o:before {
    content: '\F197';
}

.axa-icons-file:before {
    content: '\F198';
}

.axa-icons-financial-loss-1-o:before {
    content: '\F199';
}

.axa-icons-financial-loss-2-o:before {
    content: '\F19A';
}

.axa-icons-finger-print-o:before {
    content: '\F19B';
}

.axa-icons-fire-car-o:before {
    content: '\F19C';
}

.axa-icons-fire-house-o:before {
    content: '\F19D';
}

.axa-icons-fire-o:before {
    content: '\F19E';
}

.axa-icons-fire:before {
    content: '\F19F';
}

.axa-icons-fishing-o:before {
    content: '\F1A0';
}

.axa-icons-flake-o:before {
    content: '\F1A1';
}

.axa-icons-flexible-contract-o:before {
    content: '\F1A2';
}

.axa-icons-flood-house-o:before {
    content: '\F1A3';
}

.axa-icons-food-poisoning-o:before {
    content: '\F1A4';
}

.axa-icons-fried-egg-o:before {
    content: '\F1A5';
}

.axa-icons-fryer-o:before {
    content: '\F1A6';
}

.axa-icons-furniture-o:before {
    content: '\F1A7';
}

.axa-icons-garage-o:before {
    content: '\F1A8';
}

.axa-icons-garage:before {
    content: '\F1A9';
}

.axa-icons-garden:before {
    content: '\F1AA';
}

.axa-icons-gauge-o:before {
    content: '\F1AB';
}

.axa-icons-gavel-o:before {
    content: '\F1AC';
}

.axa-icons-gear-o:before {
    content: '\F1AD';
}

.axa-icons-gear:before {
    content: '\F1AE';
}

.axa-icons-gender-o:before {
    content: '\F1AF';
}

.axa-icons-glossary-o:before {
    content: '\F1B0';
}

.axa-icons-gps-o:before {
    content: '\F1B1';
}

.axa-icons-graduation-hat-o:before {
    content: '\F1B2';
}

.axa-icons-graph-o:before {
    content: '\F1B3';
}

.axa-icons-ground-floor:before {
    content: '\F1B4';
}

.axa-icons-group:before {
    content: '\F1B5';
}

.axa-icons-growth-dollar-o:before {
    content: '\F1B6';
}

.axa-icons-growth-euro-o:before {
    content: '\F1B7';
}

.axa-icons-growth-o:before {
    content: '\F1B8';
}

.axa-icons-growth-pound-o:before {
    content: '\F1B9';
}

.axa-icons-growth-yen-o:before {
    content: '\F1BA';
}

.axa-icons-growth:before {
    content: '\F1BB';
}

.axa-icons-hacking-o:before {
    content: '\F1BC';
}

.axa-icons-hand-cross-o:before {
    content: '\F1BD';
}

.axa-icons-hand-euros-o:before {
    content: '\F1BE';
}

.axa-icons-hand-heart-o:before {
    content: '\F1BF';
}

.axa-icons-hand-leaf-o:before {
    content: '\F1C0';
}

.axa-icons-handicapped:before {
    content: '\F1C1';
}

.axa-icons-handshake-o:before {
    content: '\F1C2';
}

.axa-icons-handshake:before {
    content: '\F1C3';
}

.axa-icons-hard-hat-o:before {
    content: '\F1C4';
}

.axa-icons-hat:before {
    content: '\F1C5';
}

.axa-icons-headset:before {
    content: '\F1C6';
}

.axa-icons-health-kit-o:before {
    content: '\F1C7';
}

.axa-icons-health-kit:before {
    content: '\F1C8';
}

.axa-icons-hearing-impaired-o:before {
    content: '\F1C9';
}

.axa-icons-heart-o:before {
    content: '\F1CA';
}

.axa-icons-heart:before {
    content: '\F1CB';
}

.axa-icons-helmet-o:before {
    content: '\F1CC';
}

.axa-icons-help-man-o:before {
    content: '\F1CD';
}

.axa-icons-help-o:before {
    content: '\F1CE';
}

.axa-icons-help-woman-o:before {
    content: '\F1CF';
}

.axa-icons-home:before {
    content: '\F1D0';
}

.axa-icons-hospital-bed-o:before {
    content: '\F1D1';
}

.axa-icons-hospital-euro-o:before {
    content: '\F1D2';
}

.axa-icons-hospital-o:before {
    content: '\F1D3';
}

.axa-icons-house-coins-o:before {
    content: '\F1D4';
}

.axa-icons-house-family-o:before {
    content: '\F1D5';
}

.axa-icons-house-o:before {
    content: '\F1D6';
}

.axa-icons-housemate-o:before {
    content: '\F1D7';
}

.axa-icons-inbox-o:before {
    content: '\F1D8';
}

.axa-icons-industry-o:before {
    content: '\F1D9';
}

.axa-icons-inflammable-liquid-o:before {
    content: '\F1DA';
}

.axa-icons-info-outline:before {
    content: '\F1DB';
}

.axa-icons-info:before {
    content: '\F1DC';
}

.axa-icons-instagram-o:before {
    content: '\F1DD';
}

.axa-icons-instagram:before {
    content: '\F1DE';
}

.axa-icons-intermediate-floor:before {
    content: '\F1DF';
}

.axa-icons-jacuzzi:before {
    content: '\F1E0';
}

.axa-icons-key-car-o:before {
    content: '\F1E1';
}

.axa-icons-key:before {
    content: '\F1E2';
}

.axa-icons-keys-o:before {
    content: '\F1E3';
}

.axa-icons-light-bulb-o:before {
    content: '\F1E4';
}

.axa-icons-lightning-o:before {
    content: '\F1E5';
}

.axa-icons-lightning:before {
    content: '\F1E6';
}

.axa-icons-linkedin-o:before {
    content: '\F1E7';
}

.axa-icons-linkedin:before {
    content: '\F1E8';
}

.axa-icons-local-hospital:before {
    content: '\F1E9';
}

.axa-icons-lock:before {
    content: '\F1EA';
}

.axa-icons-magnifier-glass-o:before {
    content: '\F1EB';
}

.axa-icons-man-elderly-o:before {
    content: '\F1EC';
}

.axa-icons-man-o:before {
    content: '\F1ED';
}

.axa-icons-man-shield-o:before {
    content: '\F1EE';
}

.axa-icons-man-tie-o:before {
    content: '\F1EF';
}

.axa-icons-man-tie-shield-o:before {
    content: '\F1F0';
}

.axa-icons-matchstick-o:before {
    content: '\F1F1';
}

.axa-icons-medal-o:before {
    content: '\F1F2';
}

.axa-icons-medical-assistance-o:before {
    content: '\F1F3';
}

.axa-icons-mental-disability-o:before {
    content: '\F1F4';
}

.axa-icons-merchendise-o:before {
    content: '\F1F5';
}

.axa-icons-mobile-check-o:before {
    content: '\F1F6';
}

.axa-icons-mobile-home-o:before {
    content: '\F1F7';
}

.axa-icons-mobile-home:before {
    content: '\F1F8';
}

.axa-icons-mobile-o:before {
    content: '\F1F9';
}

.axa-icons-mobile:before {
    content: '\F1FA';
}

.axa-icons-more-horiz:before {
    content: '\F1FB';
}

.axa-icons-more-less:before {
    content: '\F1FC';
}

.axa-icons-more-o:before {
    content: '\F1FD';
}

.axa-icons-more-vert:before {
    content: '\F1FE';
}

.axa-icons-more:before {
    content: '\F1FF';
}

.axa-icons-moto:before {
    content: '\F200';
}

.axa-icons-motor-disability-o:before {
    content: '\F201';
}

.axa-icons-motorcycle-o:before {
    content: '\F202';
}

.axa-icons-mpv-o:before {
    content: '\F203';
}

.axa-icons-newspaper-o:before {
    content: '\F204';
}

.axa-icons-newspepper:before {
    content: '\F205';
}

.axa-icons-ocular-disability-o:before {
    content: '\F206';
}

.axa-icons-optical-o:before {
    content: '\F207';
}

.axa-icons-owner:before {
    content: '\F208';
}

.axa-icons-padlock-o:before {
    content: '\F209';
}

.axa-icons-padlock:before {
    content: '\F20A';
}

.axa-icons-paint-roll-o:before {
    content: '\F20B';
}

.axa-icons-penalty-o:before {
    content: '\F20C';
}

.axa-icons-person:before {
    content: '\F20D';
}

.axa-icons-phone-o:before {
    content: '\F20E';
}

.axa-icons-phone:before {
    content: '\F20F';
}

.axa-icons-piggy-bank-o:before {
    content: '\F210';
}

.axa-icons-piggy-health-o:before {
    content: '\F211';
}

.axa-icons-pin-locator-map-o:before {
    content: '\F212';
}

.axa-icons-pin-locator-o:before {
    content: '\F213';
}

.axa-icons-pin-locator:before {
    content: '\F214';
}

.axa-icons-plane-o:before {
    content: '\F215';
}

.axa-icons-plane:before {
    content: '\F216';
}

.axa-icons-plaster-crutch:before {
    content: '\F217';
}

.axa-icons-play-o:before {
    content: '\F218';
}

.axa-icons-plug-adaptator-o:before {
    content: '\F219';
}

.axa-icons-plug-o:before {
    content: '\F21A';
}

.axa-icons-plus-o:before {
    content: '\F21B';
}

.axa-icons-pool:before {
    content: '\F21C';
}

.axa-icons-pound:before {
    content: '\F21D';
}

.axa-icons-pourcentage:before {
    content: '\F21E';
}

.axa-icons-power-button-o:before {
    content: '\F21F';
}

.axa-icons-power-button:before {
    content: '\F220';
}

.axa-icons-printer-o:before {
    content: '\F221';
}

.axa-icons-printer:before {
    content: '\F222';
}

.axa-icons-private-mansion-o:before {
    content: '\F223';
}

.axa-icons-promotion-o:before {
    content: '\F224';
}

.axa-icons-puzzle-o:before {
    content: '\F225';
}

.axa-icons-question-mark-o:before {
    content: '\F226';
}

.axa-icons-question-mark:before {
    content: '\F227';
}

.axa-icons-radiator-o:before {
    content: '\F228';
}

.axa-icons-radio-button-check:before {
    content: '\F229';
}

.axa-icons-radio-o:before {
    content: '\F22A';
}

.axa-icons-rain-cloud-o:before {
    content: '\F22B';
}

.axa-icons-refregirator-o:before {
    content: '\F22C';
}

.axa-icons-refresh:before {
    content: '\F22D';
}

.axa-icons-relocation-o:before {
    content: '\F22E';
}

.axa-icons-remote-control-o:before {
    content: '\F22F';
}

.axa-icons-remove-circle:before {
    content: '\F230';
}

.axa-icons-responsibility:before {
    content: '\F231';
}

.axa-icons-ring-o:before {
    content: '\F232';
}

.axa-icons-ring:before {
    content: '\F233';
}

.axa-icons-rocking-chair-o:before {
    content: '\F234';
}

.axa-icons-run:before {
    content: '\F235';
}

.axa-icons-sail-boat-o:before {
    content: '\F236';
}

.axa-icons-saloon-car-o:before {
    content: '\F237';
}

.axa-icons-sanitary-o:before {
    content: '\F238';
}

.axa-icons-sanitary-open-o:before {
    content: '\F239';
}

.axa-icons-satchel-o:before {
    content: '\F23A';
}

.axa-icons-savings-dollar:before {
    content: '\F23B';
}

.axa-icons-savings-euro:before {
    content: '\F23C';
}

.axa-icons-savings-o:before {
    content: '\F23D';
}

.axa-icons-savings-pound-o:before {
    content: '\F23E';
}

.axa-icons-savings-yen-o:before {
    content: '\F23F';
}

.axa-icons-scales-o:before {
    content: '\F240';
}

.axa-icons-search:before {
    content: '\F241';
}

.axa-icons-seat-belt-o:before {
    content: '\F242';
}

.axa-icons-secateur:before {
    content: '\F243';
}

.axa-icons-security-camera-o:before {
    content: '\F244';
}

.axa-icons-shape-customer-area-o:before {
    content: '\F245';
}

.axa-icons-share-o:before {
    content: '\F246';
}

.axa-icons-share:before {
    content: '\F247';
}

.axa-icons-shield-3-insurance:before {
    content: '\F248';
}

.axa-icons-shield-5-insurance:before {
    content: '\F249';
}

.axa-icons-shield-browth-o:before {
    content: '\F24A';
}

.axa-icons-shield-family-o:before {
    content: '\F24B';
}

.axa-icons-shield-more-less-o:before {
    content: '\F24C';
}

.axa-icons-shield-o:before {
    content: '\F24D';
}

.axa-icons-shield:before {
    content: '\F24E';
}

.axa-icons-shopping-trolley-o:before {
    content: '\F24F';
}

.axa-icons-ski-o:before {
    content: '\F250';
}

.axa-icons-sofa:before {
    content: '\F251';
}

.axa-icons-spa:before {
    content: '\F252';
}

.axa-icons-spanner-o:before {
    content: '\F253';
}

.axa-icons-spot-hand-o:before {
    content: '\F254';
}

.axa-icons-spychological-disability-o:before {
    content: '\F255';
}

.axa-icons-stag-o:before {
    content: '\F256';
}

.axa-icons-star:before {
    content: '\F257';
}

.axa-icons-stethoscope-o:before {
    content: '\F258';
}

.axa-icons-suitcase-o:before {
    content: '\F259';
}

.axa-icons-sun-o:before {
    content: '\F25A';
}

.axa-icons-suv-o:before {
    content: '\F25B';
}

.axa-icons-swap-horiz:before {
    content: '\F25C';
}

.axa-icons-symbol-dollar-o:before {
    content: '\F25D';
}

.axa-icons-symbol-euro-o:before {
    content: '\F25E';
}

.axa-icons-symbol-pound-o:before {
    content: '\F25F';
}

.axa-icons-symbol-yen-o:before {
    content: '\F260';
}

.axa-icons-tablet-o:before {
    content: '\F261';
}

.axa-icons-tablet:before {
    content: '\F262';
}

.axa-icons-taxi-5:before {
    content: '\F263';
}

.axa-icons-taxi-o:before {
    content: '\F264';
}

.axa-icons-taxi:before {
    content: '\F265';
}

.axa-icons-terrace:before {
    content: '\F266';
}

.axa-icons-theft:before {
    content: '\F267';
}

.axa-icons-thief-o:before {
    content: '\F268';
}

.axa-icons-thumb-o:before {
    content: '\F269';
}

.axa-icons-time-o:before {
    content: '\F26A';
}

.axa-icons-tools-1-o:before {
    content: '\F26B';
}

.axa-icons-tools-2-o:before {
    content: '\F26C';
}

.axa-icons-tools:before {
    content: '\F26D';
}

.axa-icons-tornado-o:before {
    content: '\F26E';
}

.axa-icons-tractor-o:before {
    content: '\F26F';
}

.axa-icons-train-o:before {
    content: '\F270';
}

.axa-icons-tram:before {
    content: '\F271';
}

.axa-icons-truck-o:before {
    content: '\F272';
}

.axa-icons-twitter-o:before {
    content: '\F273';
}

.axa-icons-twitter:before {
    content: '\F274';
}

.axa-icons-umbrella-o:before {
    content: '\F275';
}

.axa-icons-upload-o:before {
    content: '\F276';
}

.axa-icons-upload:before {
    content: '\F277';
}

.axa-icons-van-o:before {
    content: '\F278';
}

.axa-icons-van:before {
    content: '\F279';
}

.axa-icons-ventilation-o:before {
    content: '\F27A';
}

.axa-icons-veranda:before {
    content: '\F27B';
}

.axa-icons-walk:before {
    content: '\F27C';
}

.axa-icons-washing-machine-o:before {
    content: '\F27D';
}

.axa-icons-watch:before {
    content: '\F27E';
}

.axa-icons-watering-can-o:before {
    content: '\F27F';
}

.axa-icons-wc-o:before {
    content: '\F280';
}

.axa-icons-wechat-o:before {
    content: '\F281';
}

.axa-icons-wheelchair-o:before {
    content: '\F282';
}

.axa-icons-wind-turbine-o:before {
    content: '\F283';
}

.axa-icons-wind-turbine:before {
    content: '\F284';
}

.axa-icons-within-1hr-o:before {
    content: '\F285';
}

.axa-icons-within-24hrs-o:before {
    content: '\F286';
}

.axa-icons-within-72hrs-o:before {
    content: '\F287';
}

.axa-icons-woman-elderly-o:before {
    content: '\F288';
}

.axa-icons-woman-o:before {
    content: '\F289';
}

.axa-icons-woman-shirt-o:before {
    content: '\F28A';
}

.axa-icons-world-o:before {
    content: '\F28B';
}

.axa-icons-yen:before {
    content: '\F28C';
}

.axa-icons-youtube-o:before {
    content: '\F28D';
}

.axa-icons-youtube:before {
    content: '\F28E';
}
