@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/source-sans-pro-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('/fonts/source-sans-pro-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
  url('/fonts/source-sans-pro-v14-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
  url('/fonts/source-sans-pro-v14-latin-regular.woff') format('woff'),
    /* Modern Browsers */
  url('/fonts/source-sans-pro-v14-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
  url('/fonts/source-sans-pro-v14-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/source-sans-pro-v14-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''), url('/fonts/source-sans-pro-v14-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
  url('/fonts/source-sans-pro-v14-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */
  url('/fonts/source-sans-pro-v14-latin-600.woff') format('woff'),
    /* Modern Browsers */
  url('/fonts/source-sans-pro-v14-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */
  url('/fonts/source-sans-pro-v14-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/source-sans-pro-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('/fonts/source-sans-pro-v18-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
  url('/fonts/source-sans-pro-v18-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
  url('/fonts/source-sans-pro-v18-latin-700.woff') format('woff'),
    /* Modern Browsers */
  url('/fonts/source-sans-pro-v18-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
  url('/fonts/source-sans-pro-v18-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}


@font-face {
  font-family: 'PublicoHeadline';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/PublicoHeadline-Bold.otf') format('opentype'), url('/fonts/PublicoHeadline-Bold-Regular.ttf') format('truetype');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: 'Source Sans Pro', serif;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

main {
  position: relative;
  display: block;
}

a {
  text-decoration: none;
}

* {
  outline-color: #00008f;
}

span[class^='MuiTypography'] {
  font-size: inherit;
}

div[role='presentation'] {
  overflow-x: hidden;
  overflow-y: auto;

  img {
    width: 100%;
  }
}

pre {
  color: white;
  border-radius: 4px;
  background-color: #191c27;
  padding: 8px 10px;
  font-size: 16px;
  margin-bottom: 4px;
  -webkit-font-smoothing: subpixel-antialiased;
  min-height: 40px;
  max-height: 600px;
  overflow: auto;
}

code[class*='language-'],
pre[class*='language-'] {
  color: rgb(191, 199, 213);
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
}

/* Code blocks */
pre[class*='language-'] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
  border-radius: 0.3em;
}

:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  background: #1d1f21;
}

// Useful classes
.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;

  &.bottom {
    bottom: 0 !important;
    top: unset !important;
  }
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.flex {
  display: flex !important;
}

.flexGrow {
  flex-grow: 700 !important;
}

.bold {
  font-weight: bold;
}

.toLeft {
  float: left !important;
}

.toRight {
  float: right !important;
}

.hide {
  display: none !important;
}

.hidden {
  position: absolute;
  overflow: hidden;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  zindex: -1;
}

.margin-0 {
  margin: 0 !important;
}

#root {
  position: relative;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
}

// Buttons
button[disabled] {
  cursor: initial !important;
}

button {
  background: transparent;
}

.react-datepicker-wrapper {
  width: 100%;
}

// Inputs

// Remove arrows on input (Chrome, Safari, Edge, Opera)
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Remove cross on input (IE11)
input::-ms-clear {
  display: none;
}

input:disabled {
  cursor: not-allowed;
  user-select: none;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.react-datepicker-popper {
  z-index: 10000 !important;
}

// Animations

@keyframes opacity {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  65% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

strong {
  font-size: inherit;
}

// Form styling

form {
  position: relative;

  &.white {
    input[class^='makeStyles'],
    select[class^='makeStyles'],
    textarea[class^='makeStyles'] {
      &:not([type='range']) {
        background-color: white;
        box-shadow: 1px 1px 5px #00000010;
      }

    }

    fieldset[aria-hidden='true'] {
      &:not([type='range']) {
        background-color: white;
        box-shadow: 1px 1px 5px #00000010;
      }
    }
  }

  &.blue {
    span[class*='caption'] {
      padding-bottom: 2px;
      color: #00008f;
      font-size: 16px;
      font-weight: 500;
    }

    input[class^='makeStyles'],
    select[class^='makeStyles'],
    textarea[class^='makeStyles'],
    input[class^='jss'],
    select[class^='jss'],
    textarea[class^='jss'],
    fieldset[aria-hidden='true'] {
      background-color: #e8e8ff;
      padding-top: 9px;
      padding-bottom: 9px;
      border: solid 1px #00008f !important;
      border-radius: 0;
      font-size: 16px;

      &:placeholder-shown {
        background-color: white;
      }

      &.empty {
        background-color: white;
      }
    }
  }

  &.transparent {
    input[class^='makeStyles'],
    select[class^='makeStyles'],
    textarea[class^='makeStyles'],
    input[class^='jss'],
    select[class^='jss'],
    textarea[class^='jss'] {
      border: solid 1px #666;
      border-radius: 0;
      background-color: rgba(255, 255, 255, 0.85) !important;
      font-size: 16px !important;

      & + div p {
        font-size: 16px !important;
      }

      &:disabled {
        background-color: #d9d9d9 !important;
      }
    }

    fieldset[aria-hidden='true'] {
      border: solid 1px #666 !important;
      border-radius: 0 !important;
      background-color: white !important;
      font-size: 16px !important;
    }
  }
}

// Loaders.Bar

.MuiLinearProgress-colorPrimary {
  background: white !important;
}

// IE Fix
input[type='password']::-ms-reveal {
  display: none;
}

select:not(.react-datepicker__month-select):not(.react-datepicker__year-select) {
  -webkit-appearance: none;
  -moz-appearance: none;

  &::-ms-expand {
    display: none;
  }
}

// Loaders.Dots

@keyframes reveal {
  0% {
    transform: scale(0.001);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.001);
  }
}

// Datepicker

.react-datepicker__month-container {
  text-transform: capitalize;
}

.react-datepicker__portal {
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.5);
}

// Tooltip

.MuiTooltip-popper {
  pointer-events: initial !important;
}

// Tooltip apex

.apex-tooltip {
  background: black;
  color: white;
  padding: 6px 12px;
  font-weight: bold;
  border: none !important;
  font-size: 16px;
}

.apexcharts-xaxistooltip-bottom {
  background: #00008f !important;
  color: white !important;
  padding: 6px 12px !important;
  border: none !important;
  border-radius: 4px !important;

  & > div {
    font-size: 15px !important;
  }

  &:before,
  &:after {
    content: none !important;
  }
}

.MuiDataGrid-cell {
  outline: none !important;
}

.pg-viewer-wrapper {
  overflow-y: unset !important;
}

.photo-viewer-container {
  width: unset !important;
  height: unset !important;
}

.photo-viewer-container > img {
  width: 100% !important;
  height: 100% !important;
}

.apexcharts-tooltip {
  overflow: initial !important;
}