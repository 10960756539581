.label-slider {
    color: #484848;
    padding-top: 0.25em;
}

[type='range'] {
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--val) - var(--min)) / var(--range));
    --sx: calc(0.5 * 1.5em + var(--ratio) * (100% - 1.5em));
    margin: 0;
    padding: 0;
    width: 100%;
    height: 24px;
    background: transparent;
    outline: none;
    font: 1em/1 arial, sans-serif;
    display: block;
    cursor: pointer;

    &:focus {
        &::-webkit-slider-thumb {
            box-shadow: 1px 1px 4px #000000, 0 0 4px #ffffff;
        }
    }

    &[disabled],
    &[disabled] + div {
        opacity: 0;
        height: 0;
        visibility: hidden;
        position: absolute;
        top: 0;
        cursor: initial;
    }
}

[type='range'],
[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
}

[type='range']::-webkit-slider-runnable-track {
    box-sizing: border-box;
    border: none;
    width: 100%;
    height: 0.5em;
    border-radius: 0.25em;
}

[type='range']::-moz-range-track {
    box-sizing: border-box;
    border: none;
    width: 100%;
    height: 0.5em;
    background: #f8f8ff;
    border-radius: 0.25em;
}

[type='range']::-ms-track {
    box-sizing: border-box;
    border: none;
    width: 100%;
    height: 0.5em;
    background: #f8f8ff;
    border-radius: 0.25em;
    color: transparent;
}

[type='range']::-moz-range-progress {
    height: 0.5em;
    background: #00008f;
}

[type='range']::-ms-fill-lower {
    height: 0.5em;
    background: #00008f;
    border-radius: 0.25em;
}

[type='range']::-ms-fill-upper {
    height: 0.5em;
    background: #f8f8ff;
    border-radius: 0.25em;
}

[type='range']::-webkit-slider-thumb {
    margin-top: -0.5em;
    box-sizing: border-box;
    border: none;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: #fdfdfd;
    box-shadow: 1px 1px 4px #000000dd, 0 0 4px #ffffff;
}

[type='range']::-moz-range-thumb {
    box-sizing: border-box;
    border: none;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: #fdfdfd;
    box-shadow: 1px 1px 1px #000000dd, 0px 0px 1px #ffffff;
}

[type='range']::-ms-thumb {
    margin-top: 0;
    box-sizing: border-box;
    border: none;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: #fdfdfd;
    box-shadow: 1px 1px 1px #000000dd, 0px 0px 1px #ffffff;
}

[type='range']::-ms-tooltip {
    display: none;
}
